/* this file is transformed by vux-loader */
/* eslint-disable */
import { BrowserMultiFormatReader, ChecksumException, FormatException } from "@zxing/library";
export default {
  name: "Scan",
  data() {
    return {
      codeReader: new BrowserMultiFormatReader(),
      videoInputDevices: [],
      currentVideoInputDevice: {},
      decodeResult: undefined
    };
  },
  methods: {
    async openScan() {
      const _this = this;
      _this.codeReader.getVideoInputDevices() //老版本listVideoInputDevices()
      .then(videoInputDevices => {
        if (videoInputDevices && videoInputDevices.length) {
          if (videoInputDevices.length > 1) {
            videoInputDevices.reverse();
          } //防止先唤出前摄像头
          _this.videoInputDevices = videoInputDevices;
          _this.currentVideoInputDevice = videoInputDevices[0];
        }
      }).catch(() => {});
    },
    decodeFromInputVideo() {
      const _this = this;
      _this.codeReader.reset();
      // 多次
      _this.codeReader.decodeFromVideoDevice(_this.currentVideoInputDevice.deviceId, "video-1", (result, err) => {
        if (result) {
          _this.decodeResult = result;
        }
        if (err) {
          if (err instanceof ChecksumException) {
            console.log("A code was found, but it's read value was not valid.");
          }
          if (err instanceof FormatException) {
            console.log("A code was found, but it was in a invalid format.");
          }
        }
      });
    },
    successDecode() {
      const _this = this;
      alert(_this.decodeResult.text);
    }
  },
  watch: {
    currentVideoInputDevice: function () {
      this.decodeFromInputVideo();
    },
    decodeResult: function () {
      this.successDecode();
    }
  },
  mounted: function () {
    this.openScan();
  },
  unmounted: function () {
    this.codeReader.reset(); //关闭摄像头
  }
};